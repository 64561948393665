var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Utdanningstilbudets kode",
      "rules": [_vm.validateMaxLength(20)].concat(_vm.validateNotEmpty),
      "counter": 20,
      "required": ""
    },
    model: {
      value: _vm.subject.educationalOfferCode,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "educationalOfferCode", $$v);
      },
      expression: "subject.educationalOfferCode"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Emnets navn",
      "rules": [_vm.validateMaxLength(100)].concat(_vm.validateNotEmpty),
      "counter": 100,
      "required": ""
    },
    model: {
      value: _vm.subject.subjectName,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "subjectName", $$v);
      },
      expression: "subject.subjectName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "122"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Emnekode",
      "rules": [_vm.validateMaxLength(50)].concat(_vm.validateNotEmpty),
      "counter": 50,
      "required": ""
    },
    model: {
      value: _vm.subject.subjectCode,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "subjectCode", $$v);
      },
      expression: "subject.subjectCode"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Emnenummer",
      "rules": [_vm.validateRange(1, 30)].concat(_vm.validateNotEmpty),
      "required": ""
    },
    model: {
      value: _vm.subject.subjectNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "subjectNumber", $$v);
      },
      expression: "subject.subjectNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Studiepoeng",
      "rules": [_vm.validateRange(1, 999)].concat(_vm.validateNotEmpty),
      "required": ""
    },
    model: {
      value: _vm.subject.credits,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "credits", $$v);
      },
      expression: "subject.credits"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Antall timer"
    },
    model: {
      value: _vm.subject.subjectHours,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "subjectHours", $$v);
      },
      expression: "subject.subjectHours"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": _vm.subject.isActive ? 'Aktiv' : 'Inaktiv',
      "color": "primary",
      "inset": "",
      "hide-details": "",
      "data-cy": "nusIsActive"
    },
    model: {
      value: _vm.subject.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.subject, "isActive", $$v);
      },
      expression: "subject.isActive"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }