var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v("Utdanningstilbuds- og emnekoder ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk i emner",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": _vm.newSubject
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Nytt emne ")], 1)];
      },
      proxy: true
    }])
  }, [_vm.deleteModalData.showModal ? _c('BaseModal', [_c('BaseConfirmModalForm', {
    attrs: {
      "modalBaseData": _vm.deleteModalData
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.deleteSubject
    }
  }, [_vm._v(" Er du sikker på at du vil slette? ")])], 1) : _vm._e(), _vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 600
    },
    on: {
      "close": _vm.closeModal,
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.modalData.showModal = false;
      }
    }
  }, [_c('SubjectCodeModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem
    },
    on: {
      "close": _vm.closeModal,
      "getSubjects": _vm.getSubjects
    }
  })], 1) : _vm._e(), _c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.subjects,
      "search": _vm.search,
      "loading": _vm.loading,
      "loading-text": "Henter emner"
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.editSubject(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-pencil")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger emne")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.showSubjectModal(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("mdi-trash-can")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett emne")])])];
      }
    }], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }