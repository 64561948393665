
import { api } from "@/api/api";
import { ApiGetCurriculumSubjectCodeDto } from "@/api/generated/Api";
import SubjectCodeModal from "@/components/administration/subjectCodes/SubjectCodeModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseConfirmModalForm from "@/components/shared/modal/BaseConfirmModalForm.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SubjectCodesPage",
  components: { BaseLayout, BaseTableFiltered, BaseModal, SubjectCodeModal, BaseConfirmModalForm },
  setup() {
    const subjects = ref<ApiGetCurriculumSubjectCodeDto[]>([]);
    const search = ref("");
    const modalData = ref(getInitialModalData());
    const loading = ref(false);
    const deleteModalData = ref(getInitialModalData());
    const store = useStore<StoreState>();

    const getSubjects = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        loading.value = true;
        subjects.value = [];

        subjects.value = (await api.curriculum.getCurriculumSubjectCodes()).data;

        loading.value = false;
      });
    };

    const newSubject = useOpenModal(ModalType.Add, "Utdanningstilbuds- og emnekode", modalData);

    const editSubject = useOpenModal(ModalType.Edit, "Utdanningstilbuds- og emnekode", modalData);

    const showSubjectModal = useOpenModal(ModalType.Delete, "Utdanningstilbuds- og emnekode", deleteModalData);

    const deleteSubject = (item: any) => {
      globalLoadingWrapper({ blocking: true }, async () => {
        try {
          await api.curriculum.deleteCurriculumSubjectCode(item.existingItem.id);
        } catch (error) {
          openNotification(store, NotificationItemType.Error, `En feil oppsto ved sletting av emnekoden`);
        } finally {
          deleteModalData.value.showModal = false;
          await getSubjects();
        }
      });
    };

    const closeModal = () => {
      modalData.value.showModal = false;
    };

    onMounted(async () => {
      await getSubjects();
    });

    return {
      subjects,
      headers,
      search,
      editSubject,
      newSubject,
      modalData,
      closeModal,
      getSubjects,
      loading,
      deleteSubject,
      showSubjectModal,
      deleteModalData,
    };
  },
});

const headers = [
  {
    text: "Utdanningstilbudets kode",
    value: "educationalOfferCode",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Emnenummer",
    value: "subjectNumber",
  },
  {
    text: "Emnekode",
    value: "subjectCode",
  },
  {
    text: "Emnets navn",
    value: "subjectName",
  },
  {
    text: "Studie timer",
    value: "subjectHours",
  },
  {
    text: "Studiepoeng",
    value: "credits",
  },
  {
    text: "Status",
    value: "isActive",
  },
];
