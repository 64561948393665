
import { api } from "@/api/api";
import { ApiGetCurriculumSubjectCodeDto, ApiUpsertCurriculumSubjectCodeDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { validateMaxLength, validateNotEmpty, validateRange } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { PropType, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "SubjectCodeModal",
  components: { BaseModalForm },
  emits: ["getSubjects"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetCurriculumSubjectCodeDto>,
    },
  },
  setup(props, { refs, emit }) {
    const getInitialSubject = (): ApiUpsertCurriculumSubjectCodeDto => ({
      educationalOfferCode: props.existingItemModal?.educationalOfferCode || "",
      subjectNumber: props.existingItemModal?.subjectNumber || 1,
      subjectCode: props.existingItemModal?.subjectCode || "",
      subjectName: props.existingItemModal?.subjectName || "",
      credits: props.existingItemModal?.credits || 1,
      subjectHours: props.existingItemModal?.subjectHours || 0,
      isActive: props.existingItemModal?.isActive ?? true,
    });

    const store = useStore<StoreState>();
    const subject = ref<ApiUpsertCurriculumSubjectCodeDto>(getInitialSubject());

    const handleSubmit = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const isValid = getValidatableRef(refs.connectForm)?.validate();
        if (!isValid) {
          return;
        }

        const payload: ApiUpsertCurriculumSubjectCodeDto = {
          educationalOfferCode: subject.value.educationalOfferCode || null,
          subjectNumber: subject.value.subjectNumber,
          subjectCode: subject.value.subjectCode || null,
          subjectName: subject.value.subjectName || null,
          credits: subject.value.credits || 0,
          subjectHours: subject.value.subjectHours || 0,
          isActive: subject.value.isActive,
        };

        try {
          if (props.modalType === ModalType.Add) {
            await api.curriculum.createCurriculumSubjectCode(payload);
            openNotification(store, NotificationItemType.Success, "Emnekode opprettet");
            emit("getSubjects");
            emit("close");
          }

          if (props.modalType === ModalType.Edit) {
            if (!props.existingItemModal) {
              return;
            }

            await api.curriculum.updateCurriculumSubjectCode(props.existingItemModal?.id, payload);
            openNotification(store, NotificationItemType.Success, "Emnekode oppdatert");
            emit("getSubjects");
            emit("close");
          }
        } catch (error) {
          openNotification(store, NotificationItemType.Error, "Handling feilet");
        }
      });
    };

    return {
      handleSubmit,
      validateNotEmpty,
      validateMaxLength,
      validateRange,
      subject,
    };
  },
});
